<script setup lang="ts">
import { useContactRequestForm } from '@b2b-platform/commerce/composables/useContactRequestForm';

const { isOpen, open } = useContactRequestForm();

const headline = 'Gemeinsam finden wir die passende Lösung.';
</script>

<template>
    <section
        id="contact-request"
        class="mt-11 bg-gray-700 lg:mt-9"
        style="content-visibility: auto"
    >
        <div
            class="mx-auto flex max-w-content items-start justify-between gap-7 px-5 py-9 lg:p-12"
        >
            <div
                class="flex flex-col items-start gap-8 text-center lg:text-left"
            >
                <BaseHeadline
                    underline-white
                    class="text-white"
                    :text="headline"
                    as="h2"
                >
                    {{ headline }}
                </BaseHeadline>

                <div
                    class="w-full"
                    :class="{ 'invisible ': !isOpen }"
                    :style="`display:grid;grid-template-rows:${
                        isOpen ? '1fr' : '0fr'
                    };transition:grid-template-rows 640ms cubic-bezier(0.77,0,0.18,1);`"
                >
                    <CommContactRequestForm
                        tracking-method="General"
                        variant="light"
                    />
                </div>

                <Transition>
                    <JamBaseButton
                        v-if="!isOpen"
                        variant="white-outline"
                        class="w-full text-center md:w-fit"
                        @click="open"
                    >
                        Jetzt kontaktieren
                    </JamBaseButton>
                </Transition>
            </div>
            <NuxtImg
                width="250"
                height="250"
                loading="lazy"
                fit="contain"
                decoding="auto"
                src="/illustrations/contact-request.svg"
                alt="Eine stilisierte Illustration einer Frau, die an einem Schreibtisch mit einem Laptop sitzt,
                  umgeben von grünen Pflanzen und schwebenden Briefkuverts, die die Produktanfrage an karriere.at darstellen."
                class="hidden lg:block"
            />
        </div>
    </section>
</template>
