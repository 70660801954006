<script setup lang="ts">
import tailwindConfig from '@b2b-platform/base/tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';

withDefaults(
    defineProps<{
        size?: 'base' | 'small';
    }>(),
    {
        size: 'base',
    },
);
const { showBundleCampaign } = useBundleCampaign();

const fullConfig = resolveConfig(tailwindConfig as any);
</script>

<template>
    <div
        v-if="showBundleCampaign"
        :class="`flex !aspect-square h-[50px] w-[50px] rotate-[10deg] items-center justify-center rounded-full bg-yellow-600 text-white`"
    >
        <div class="flex">
            <JamBaseText variant="xl">%</JamBaseText>
        </div>
    </div>
</template>
